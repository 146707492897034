/* eslint-disable */
import React from "react"
import loadable from "@loadable/component"

const Layout = loadable(() => import("components/layout"))
const Box = loadable(() => import("components/box"))
const Head = loadable(() => import("components/head"))
const Title = loadable(() => import("components/title"))
const BookDetails = loadable(() => import("./book-details"))
const Header = loadable(() => import("components/header"));

import { siteTitle } from "../../site-config"
import { Loader } from "./bookwrapper.css"

const Post = ({ pageContext }) => {
  // console.log('post data::', pageContext);
  // const { next, prev } = pageContext;
  return (
    <div>
      <Header title={siteTitle} />
      <Layout fallback={<Loader/>}>
        <Head
          pageTitle={pageContext.title}
          preload={`/download/?file=${pageContext.title || pageContext.original_title}&size=${Math.floor(Math.random()*20)}&pages=${pageContext.num_pages || Math.floor(Math.random()*400)}`}
          dataset={{
            slug: pageContext.slug,
            title: pageContext.title,
            images: [
              pageContext.image_url,
              pageContext.large_image_url,
              pageContext.small_image_url,
            ],
            date: new Date(
              `${pageContext.publication_month || 7}/${
                pageContext.publication_day || 5
              }/${pageContext.publication_year || 2002}`
            ).toISOString(),
            author: pageContext?.authors?.author[0]?.name || "unknown",
            description: pageContext.description,
            headline: pageContext.title,
            publisher: pageContext.publisher,
          }}
        />

        <Title as="h1" size="large">
          {pageContext.title}
        </Title>
        <div style={{display: "flex", justifyContent: "center"}}>
          <Box>
            <BookDetails {...pageContext} />
          </Box>
        </div>
      </Layout>
    </div>
  )
}

export default Post
